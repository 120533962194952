<template>
  <div>
    <div class="container d-flex flex-column align-center justify-center">
      <div
        class="d-flex align-center justify-center"
        style="width: 45%; height: 45%"
      >
        <img
          src="../../assets/img/error.png"
          class="mr-2"
          alt="logo-error"
          height="50%"
          style="z-index: 1"
        />
      </div>
      <p class="txt_notfound my-5">Oops! Something Went Wrong</p>
      <p class="txt_desc mb-0">
        Sorry, 500 server error. Try to refresh previous page.
      </p>
      <p class="txt_desc mb-5">
        Feel free to contact us in 'Customer Services' menu or create complant
        in 'Pengaduan' menu. See you later!
      </p>
      <v-btn
        class="px-10"
        depressed
        @click="$router.go(-2)"
        dark
        color="#052633"
      >
        <h4>back</h4>
      </v-btn>
    </div>
  </div>
</template>

<script>
export default {
  name: "internalServerError",
};
</script>

<style>
.container {
  width: 100%;
  height: 90vh;
}
.txt_notfound {
  font-size: 150%;
  text-align: center;
  color: #052633;
  font-weight: bold;
}
.txt_desc {
  font-size: 100%;
  text-align: center;
  color: #052633;
}
</style>
